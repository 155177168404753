<template>
  <div class="container">
    <div class="px-6 py-4 sticky searchbar">
      <div class="flex w-full rounded-md border-gray-400 border px-4 items-center gap-3">
        <Search/>
        <input
          class="w-full outline-none py-2"
          type="text"
          placeholder="Nama Lembaga Pelatihan"
          v-model="searchTerm"
        />
      </div>
    </div>
    <div
      v-for="platform in platforms"
      :key="platform.name"
    >
      <a :href="`https://wa.me/${platform.wa_number}`" target="_blank">
        <div class="flex p-4 justify-between px-6 items-center gap-4">
          <div class="font-bold">
            {{ platform.name }}
          </div>
          <div class="flex gap-2 items-center">
            <img :src="platform.logo" class="logo"/>
            <Chevron color="#3b82f6"/>
          </div>
        </div>
      </a>
    </div>
    <div v-if="platforms.length === 0 && searchTerm.trim().length > 0" class="flex justify-center py-2">
      Lembaga Pendidikan tidak ditemukan
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Chevron'),
    Search: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Search')
  },
  data: () => ({
    searchTerm: '',
    allPlatforms: [],
    platforms: []
  }),
  watch: {
    searchTerm(val) {
      this.platforms = this.allPlatforms.filter(item => item.name.toLocaleLowerCase().includes(val.trim().toLocaleLowerCase()))
    }
  },
  methods: {
    ...mapActions('data', ['getEducationalInstitutions']),
    async getData() {
      const data = await this.getEducationalInstitutions({
        slug: ''
      })
      const platforms = data.data
      this.allPlatforms = platforms
      this.platforms = platforms
    }
  },
  mounted() {
    this.getData()
  }
}

</script>

<style lang="scss" scoped>
  .container {
    max-width: 640px;
    margin-bottom: 50px;
  }
  .logo {
    max-width: 110px;
  }

  .searchbar {
    position: sticky;
    background-color: white;

    @media screen and (max-width: 480px) {
      top: 56px;
    }
    @media screen and (min-width: 480px) {
      top: 80px;
    }
    @media screen and (min-width: 780px) {
      top: 74px;
    }
  }
</style>
